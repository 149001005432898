<!--vip订单-->
<template>
    <section  class="vip-order__wrapper">
        <div  class="vip-manage-page-title-con">
            <p>VIP订单</p>
            <div class="vip-content-header">
                <span :class="{'active': orderIndex == 0}" @click="changeOrderIndex(0)">全部订单</span>
                <span :class="{'active': orderIndex == 1}" @click="changeOrderIndex(1)">待支付</span>
                <span :class="{'active': orderIndex == 2}" @click="changeOrderIndex(2)">已完成</span>
            </div>
        </div>
        <ul  style="padding-bottom: 30px;">
            <li  class="table_header">
                <div  class="table_header_con">
                    <p >订单类型</p>
                    <p >套餐金额</p>
                    <p >详情</p>
                    <p >订单状态</p>
                    <p >操作</p>
                </div>
            </li>
            <li  class="vip-order__block">
                <header  class="order-detail-header">
                    <span  style="color: rgba(0, 0, 0, 0.4);">2019/10/4 14:40&nbsp;&nbsp;&nbsp;&nbsp;订单号：</span>201191004144025325013199488DtXq
                </header>
                <div  class="vip-order__detail">
                    <div  class="order_item">
                        <p >标准VIP-1个月</p>
                    </div>
                    <div  class="order_item vip-order__price">
                        <p >￥29.00</p>
                    </div>
                    <div  class="order_item vip-order__des">
                        <p  class="description">标准VIP*1月*1人</p>
                    </div>
                    <div  class="order_item">
                        <p  class="order-status">已完成</p>
                    </div>
                    <div  class="order_item btn-con">
                        <span >-</span>
                    </div>
                </div>
            </li>
        </ul>
        <!--<div  class="vip-order__none-order" v-if="orderList.length<=0">
            <span  class="none-order-icon"></span>
            <p  class="none-order-title">暂时没有任何订单</p>
        </div>-->
    </section>
</template>

<script>
export default{
    data () {
        return {
            orderList: [],
            orderIndex: 0
        }
    },
    created () {

    },
    methods: {
        changeOrderIndex (index) {
            this.orderIndex = index
        }
    }
}
</script>