<!--收支-->
<template>
    <section  class="vm-amount-io__wrapper">
        <div class="amount-manage-page-title-con">
            <p>余额收支</p>
            <div class="amount-content-header">
                <span :class="{'active':payIndex == 0}" @click="changePayIndex(0)">全部订单</span>
                <span :class="{'active':payIndex == 1}" @click="changePayIndex(1)">收入</span>
                <span :class="{'active':payIndex == 2}" @click="changePayIndex(2)">支出</span>
            </div>
        </div>
        <ul class="vm-amount-io__list" style="padding-bottom: 30px;" v-if="payList.length>0">
            <li  class="table_header">
                <div class="table_header_con">
                    <p >订单类型</p>
                    <p >套餐金额</p>
                    <p >订单状态</p>
                    <p >详情</p>
                </div>
            </li>
            <li class="vm-amount-io__block">
                <header  class="order-detail-header">
                    <span  style="color: rgba(0, 0, 0, 0.4);">2019/10/4 14:40&nbsp;&nbsp;&nbsp;&nbsp;订单号：</span>301191004144025320013199488vyKn
                </header>
                <div  class="vm-amount-io__detail">
                    <div  class="order_item">
                        <span  class="brt">购买会员</span>
                    </div>
                    <div  class="order_item">
                        <span  class="amount negative">-￥29.00</span>
                    </div>
                    <div  class="order_item">
                        <span  class="order-status">支付成功</span>
                    </div>
                    <div  class="order_item" style="color: rgba(0, 0, 0, 0.6);">-</div>
                </div>
            </li>
            <li  class="vm-amount-io__block">
                <header  class="order-detail-header">
                    <span  style="color: rgba(0, 0, 0, 0.4);">2019/10/4 14:39&nbsp;&nbsp;&nbsp;&nbsp;订单号：</span>302191004143942311013199488bJeY
                </header>
                <div  class="vm-amount-io__detail">
                    <div  class="order_item">
                        <span  class="brt">虚拟货币充值</span>
                    </div>
                    <div  class="order_item">
                        <span  class="amount">+￥50.00</span>
                    </div>
                    <div  class="order_item">
                        <span  class="order-status">支付成功</span>
                    </div>
                    <div  class="order_item" style="color: rgba(0, 0, 0, 0.6);">-</div>
                </div>
            </li>
        </ul>
        <div class="vm-amount-io__none-order" v-if="payList.length<=0">
            <span class="none-order-icon"></span>
            <p class="none-order-title">暂时没有任何订单</p>
        </div>
    </section>
</template>

<script>
export default {
    data () {
        return {
            payList: [],
            payIndex: 0
        }
    },
    created () {
        this.init()
    },
    methods: {
        init () {

        },
        changePayIndex (index) {
            this.payIndex = index
        }
    }
}
</script>