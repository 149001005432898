<template>
  <div id="main-content" class>
    <section
      class="outer-wrapper"
      isvip="2"
      islogin="true"
      teamid="2543363"
      teams="[object Object]"
      orderno
      nothumbcolor=""
      bindingtype="手机"
      scrolltop="0"
      style="position: relative;"
      isresize="true">
      <section class="list-page">
        <div class="list-page__option-wrap">
          <span
            class="list-page__option-block"
            :class="{'active': balanceIndex == 0}"
            @click="changeBalanceIndex(0)"
          >邀请记录</span>
          <span
            class="list-page__option-block"
            :class="{'active': balanceIndex == 1}"
            @click="changeBalanceIndex(1)"
          >余额收支</span>
        </div>
        <payments v-if="balanceIndex == 1"></payments>
        <order v-if="balanceIndex == 0"></order>
      </section>
    </section>
  </div>
</template>

<script>
import payments from "../../components/balance/payments.vue";
import order from "../../components/balance/order.vue";
export default {
  data() {
    return {};
  },
  components: {
    payments,
    order
  },
  computed: {
    balanceIndex() {
      return this.$store.state.common.balanceIndex;
    }
  },
  methods: {
    changeBalanceIndex(index) {
      this.$store.dispatch("setBalanceIndex", index);
    }
  }
};
</script>